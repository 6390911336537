import Head from 'next/head';

import Props from './Layout.types';

import { constants } from '../../constants';
import { useEffect } from 'react';
import { updateDataLayerObj } from '../../utils';

const { POLITE } = constants;

export const Layout = ({
  children,
  title,
  metaNames,
  metaProps,
  pageIdentifier,
  model,
  ariaHidden = true,
  ariaLive = 'polite',
}: Props) => {
  useEffect(() => {
    updateDataLayerObj(window.dataLayer);
  }, []);
  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <title>{title}</title>
        <link rel="icon" type="image/x-icon" href={model.favIcon} />
        {metaNames?.map(metaName => (
          <meta name={metaName.name} content={metaName.value} key={metaName.value}></meta>
        ))}
        {metaProps?.map(metaProperty => (
          <meta property={metaProperty.name} content={metaProperty.value} key={metaProperty.value}></meta>
        ))}
        <link rel="canonical" href={model.canonical} />
        <script src={model.launchScript} async></script>
        <script src={model.dataLayerScript} async></script>
      </Head>
      <div className="brand-css" aria-hidden={ariaHidden} aria-live={ariaLive} aria-atomic={ariaLive === POLITE}>
        <div id="main-content" className={`${model.cssClassNames} ${pageIdentifier}`}>
          {children}
        </div>
      </div>
    </>
  );
};
