import { useEffect } from 'react';
import { getViewPortValue } from './helper';
import { Splide } from '@splidejs/splide';
import { constants } from '../constants';

interface SplideProps {
  pageData: {
    offersCardsType: 'layeredSquare_4cards' | 'layeredSquare_3cards' | 'layeredSquare_2cards' | 'layeredSquare_1cards';
    componentId: string;
    cardContentLength?: number;
  };
  setScreenType: CallableFunction;
  setActiveIndex: CallableFunction;
  config: {
    layeredSquare_1cards: configInterface;
    layeredSquare_2cards: configInterface;
    layeredSquare_3cards: configInterface;
    layeredSquare_4cards: configInterface;
  };
}

interface Options {
  perPage: number;
  gap: string;
  height: string;
  arrows: boolean;
  fixedWidth: string;
  width?: string;
  speed: number;
  pagination: boolean;
  type: string;
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Splide: any;
  }
}

interface configInterface {
  type: string;
  perPage: {
    mobile: number;
    tablet: number;
    desktop: number;
  };
  height: {
    mobile: string;
    tablet: string;
    desktop: string;
  };
  width: {
    mobile: string;
    tablet: string;
    desktop: string;
  };
  gap: {
    mobile: string;
    tablet: string;
    desktop: string;
  };
  carousalWidth: {
    mobile: string;
    tablet: string;
    desktop: string;
  };
  titleClass: string;
  marginClass: string;
  imageType: {
    mobile: string;
    tablet: string;
    desktop: string;
  };
}

const getCardDetails = (
  pageProps: {
    offersCardsType: 'layeredSquare_4cards' | 'layeredSquare_3cards' | 'layeredSquare_2cards' | 'layeredSquare_1cards';
    cardContentLength?: number;
  },
  config: {
    layeredSquare_1cards: configInterface;
    layeredSquare_2cards: configInterface;
    layeredSquare_3cards: configInterface;
    layeredSquare_4cards: configInterface;
  }
): Options => {
  const cardType = pageProps.offersCardsType;
  const screenType: 'mobile' | 'tablet' | 'desktop' = getViewPortValue(
    cardType === 'layeredSquare_3cards' ? constants.BREAKPOINT_MAX_DESKTOP : constants.BREAKPOINT_DESKTOP
  );
  const con = config[cardType];
  return {
    type: con.type,
    perPage: con.perPage[screenType],
    fixedWidth: con.width[screenType],
    height: con.height[screenType],
    gap: con.gap[screenType],
    arrows: true,
    width: con.carousalWidth[screenType],
    speed: 500,
    pagination: screenType !== 'mobile',
    ...(pageProps?.cardContentLength && {
      pagination: screenType !== 'mobile' && pageProps?.cardContentLength !== con.perPage[screenType],
    }),
  };
};

export const useSplideJS = (props: SplideProps): null => {
  const { pageData, setScreenType, config, setActiveIndex } = props;

  useEffect(() => {
    const setAndInitSplide = () => {
      setScreenType(getViewPortValue());
      const preOptions = getCardDetails(pageData, config);
      const splideCarousel = new Splide(`#id${pageData.componentId}`, preOptions);
      splideCarousel.on('ready', () => {
        // ready and active are Splide event listnerers. https://splidejs.com/guides/events/#ready
        setScreenType(getViewPortValue());
        const options = getCardDetails(pageData, config);
        splideCarousel.options = options;
      });

      splideCarousel.on('active', (data: { index: number }) => {
        // ready and active are Splide event listnerers. https://splidejs.com/guides/events/#active
        const { index } = data;
        setActiveIndex(index);
      });

      splideCarousel.mount();

      // Return the cleanup function
      return () => {
        // Unmount and destroy Splide instance
        splideCarousel.destroy();
      };
    };

    // Call the function that sets up Splide
    const cleanupSplide = setAndInitSplide();

    // Cleanup function for unmounting
    return () => {
      // Call the cleanup function to unmount Splide
      cleanupSplide();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
