import cssClass from 'classnames';
import { FC, useMemo } from 'react';

import { constants, CUSTOM_EVENTS_CONSTANTS, TRACKING_CONSTANT, URL_CONSTANTS, ENV_CONSTANTS } from '../../constants';
import { RoomUpgradeCardProps } from './RoomUpgradeCard.types';
import { StyledContent, StyledContainer, StyleImage } from './RoomUpgradeCard.styles';

import { Button } from '../../atoms';
import { filterRoomUpgradeImages, getCardData, handleClick } from './helper';
import { RoomImageCarousel } from '../../organisms/RoomImageCarousel';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';

export const RoomUpgradeCard: FC<RoomUpgradeCardProps> = props => {
  const {
    title,
    type,
    ctaText,
    primaryCtaText,
    trackingProperties,
    roomUpgradeData,
    roomImageData,
    buttonEnabled,
    photoSoon,
    night,
    target,
    handleUpgradeClick,
    totalPerRoom = '',
  } = props;

  const { ROOM_UPGRADE_TYPE } = constants;
  const { ROOM_DETAILS_ID, ROOM_IMAGES_TYPE } = constants;
  const { ON_SHOW_ROOM_DETAIL } = CUSTOM_EVENTS_CONSTANTS;
  const { envVarsObject } = useClientEnvVarsStore();
  const imageDomain = envVarsObject?.[ENV_CONSTANTS.uxlImageCacheDomain] ?? URL_CONSTANTS.CACHE_MARRIOTT_URL;

  const upsellData = getCardData({
    type,
    roomUpgradeData,
    title,
  });

  const filteredRoomImages = useMemo(
    () => filterRoomUpgradeImages(roomImageData, roomUpgradeData.roomPoolCode, imageDomain),
    [roomUpgradeData.roomPoolCode]
  );

  const roomUpgradeType = type === ROOM_UPGRADE_TYPE;

  const btnClassName = cssClass('m-button-s button', { disabled: !buttonEnabled });
  const roomDetailsClassName = cssClass('standard', {
    'upsell-room-details d-inline-block': roomUpgradeType,
  });

  const roomName = `${roomUpgradeData.propertyHeading ?? ''}, ${roomUpgradeData.propertyDescription ?? ''}`;
  const roomModalData = { description: roomName, roomDetailsList: [{ roomPoolCode: roomUpgradeData.roomPoolCode }] };
  const updatedCurrency = roomUpgradeData?.upsellCurrency?.getSymbol();

  return (
    <StyledContainer
      data-component-name="m-book-RoomUpgradeCard"
      data-testid="book-RoomUpgradeCard"
      className={`${type}-container`}
      id={roomUpgradeData.roomPoolCode}
    >
      <StyleImage>
        <RoomImageCarousel
          roomPoolCode={roomUpgradeData?.roomPoolCode || ''}
          propertyId={roomUpgradeData?.marshaCode}
          cardType="layeredSquare_1cards"
          compType={ROOM_IMAGES_TYPE}
          handleImageClick={() => handleClick({ id: ROOM_DETAILS_ID, item: roomModalData, key: ON_SHOW_ROOM_DETAIL })}
          imagePlaceHolder={photoSoon || ''}
          filteredRoomImages={filteredRoomImages}
        />
      </StyleImage>
      <StyledContent>
        <div className="top-section">
          <h4 className="heading standard">{upsellData.updatedTitle}</h4>
          <Button
            isLink={true}
            href="#"
            callback={e => handleClick({ e: e, id: ROOM_DETAILS_ID, item: roomModalData, key: ON_SHOW_ROOM_DETAIL })}
            className={roomDetailsClassName}
          >
            {ctaText}
          </Button>
        </div>
        <div className="bottom-section">
          <section className="price-block">
            <span className="room-type-text">{roomUpgradeData?.rateName}</span>
            <span className="price-text">+{upsellData.upsellPrice} </span>
            <span className="per-night-text">
              {updatedCurrency} / {night}
            </span>
            <span className="grand-total-text">
              {upsellData.grandTotal} {totalPerRoom?.toLowerCase()}
            </span>
          </section>
          <Button
            isLink={true}
            href="#"
            target={target}
            className={btnClassName}
            trackingProperties={trackingProperties}
            linkType={TRACKING_CONSTANT.INTERNAL_LINK}
            callback={e => handleUpgradeClick?.(e)}
          >
            {primaryCtaText}
          </Button>
        </div>
      </StyledContent>
    </StyledContainer>
  );
};
