import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledHeroBannerCarousel = styled.div`
  .outlet-hero-banner--img {
    width: 100%;
    aspect-ratio: 1/1;
    max-height: ${toRem(310)};

    @media ${baseVariables.mediaQuery.md} {
      aspect-ratio: 2/1;
      max-height: ${toRem(480)};
    }

    @media ${baseVariables.mediaQuery.md} {
      aspect-ratio: 3/1;
      max-height: ${toRem(480)};
    }

    @media ${baseVariables.mediaQuery.md} {
      max-height: unset;
    }
  }

  .spinner-wrapper {
    position: absolute;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .m-spinner-m {
      z-index: 99;
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  .rnb-search-form {
    display: none !important;
  }
  .spinner-wrapper {
    min-height: ${toRem(500)};
  }
`;

export const ShowMiniCards = createGlobalStyle`
  .recommended-mini-card-wrapper {
    display: block !important;
  }
`;

interface StyledOverlayProps {
  iconPath: boolean;
}

export const StyledOverlayContainer = styled.div<StyledOverlayProps>`
  position: relative;
  .overlay-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${props => (props.iconPath ? '100%' : '120%')};
    width: 100%;
    opacity: ${props => (props.iconPath ? '0.7' : 'initial')};
    background: ${props => (props.iconPath ? `${baseVariables.color.base10}` : 'none')};

    .icon-dining {
      font-size: ${toRem(24)};
      line-height: ${toRem(28)};
      border-bottom: ${toRem(5)} solid #ff8d6b;
      padding-bottom: ${toRem(12)};
      @media ${baseVariables.mediaQuery.md && baseVariables.mediaQuery.lg} {
        font-size: ${toRem(32)};
        line-height: ${toRem(36)};
        border-bottom: ${toRem(7)} solid #ff8d6b;
        padding-bottom: ${toRem(16)};
      }
    }
  }
`;

export const StyledCityPageHeroBanner = styled.div`
  position: relative;

  img {
    width: 100%;
    aspect-ratio: 1/1;
  }

  @media ${baseVariables.mediaQuery.md} {
    img {
      aspect-ratio: 3/1;
    }
  }

  .bl_iconText-wrapper {
    position: absolute;
    padding: ${toRem(3)} ${toRem(8)};
    background-color: rgba(28, 28, 28, ${baseVariables.opacity['opacity5']});
    border-radius: ${toRem(5)};
    right: ${toRem(46)};
    bottom: ${toRem(14)};

    @media ${baseVariables.mediaQuery.sm} {
      right: ${toRem(38)};
      bottom: ${toRem(14)};
    }
    @media ${baseVariables.mediaQuery.lg} {
      right: ${toRem(158)};
      bottom: ${toRem(35)};
    }
  }
`;

export const StyledCampaignHeroBanner = styled.div<{ styleClass: string }>`
  position: relative;
  .bl__cnt-sec {
    position: absolute;
    display: flex;
    align-items: end;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    @media ${baseVariables.mediaQuery.sm} {
      align-items: center;
    }
  }
  .bl__cnt-ctr {
    position: relative;
    z-index: 1;
    padding: 0 ${toRem(16)} ${toRem(36)};
    margin: 0 auto ${toRem(20)} auto;
    @media ${baseVariables.mediaQuery.sm} {
      margin: 0 auto;
    }
    @media ${baseVariables.mediaQuery.sm} {
      padding-bottom: ${toRem(0)};
    }
  }
  img {
    width: 100%;
    aspect-ratio: 1/1;
    @media ${baseVariables.mediaQuery.md} {
      aspect-ratio: 3/1;
    }
  }
  .bl_iconText-wrapper {
    position: absolute;
    padding: ${toRem(3)} ${toRem(8)};
    background-color: rgba(28, 28, 28, ${baseVariables.opacity['opacity5']});
    border-radius: ${toRem(5)};
    right: ${toRem(27)};
    top: ${toRem(16)};
    @media ${baseVariables.mediaQuery.sm} {
      right: ${toRem(27)};
      top: ${toRem(16)};
    }
    @media ${baseVariables.mediaQuery.lg} {
      right: ${toRem(16)};
      top: ${toRem(16)};
    }
  }

  .gradient {
    &:after {
      height: 60%;
      background: ${props =>
        props?.styleClass?.includes('inverse')
          ? `${baseVariables.gradient['baseGradient05']}`
          : `${baseVariables.gradient['baseGradient01']}`};
      @media ${baseVariables.mediaQuery.sm} {
        height: 100%;
        background: ${props =>
          props?.styleClass?.includes('inverse')
            ? `${baseVariables.gradient['baseGradient06']}`
            : `${baseVariables.gradient['baseGradient02']}`};
      }
      @media ${baseVariables.mediaQuery.lg} {
        height: 100%;
        background: ${props =>
          props?.styleClass?.includes('inverse')
            ? `${baseVariables.gradient['baseGradient07']}`
            : `${baseVariables.gradient['baseGradient03']}`};
      }
      display: block;
      width: 100%;
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
`;
