import { useEffect } from 'react';

import { Button } from '../../atoms';
import { ReservationModalProps } from './SaveReservation.types';

import { eventUtil } from '../../utils';
import { TRACKING_CONSTANT, constants } from '../../constants';
const { INTERNAL_LINK } = TRACKING_CONSTANT;

export const SaveReservationPhoenix = ({
  btnText,
  customCss,
  trackingProperties,
  buttonEnabled,
  isParam,
  setOcjURL,
  buttonClass = '',
  modalType,
}: ReservationModalProps) => {
  const handleOpen = (type?: string | undefined) => {
    eventUtil.dispatch(
      constants.SIGNIN_MODAL_OPEN_EVENT, //event that will trigger the modal to open
      {
        modalType: type ? type : modalType,
        randParameter: decodeURIComponent(isParam ?? ''),
      }
    );
  };

  useEffect(() => {
    if (isParam && setOcjURL) {
      handleOpen(constants.JOIN_AND_SAVE);
    }
  }, [isParam]);

  return btnText ? (
    <div className={customCss}>
      <Button
        text={btnText}
        className={`m-button-s m-button-primary ${buttonEnabled ? '' : 'disabled'} ${buttonClass}`}
        trackingProperties={trackingProperties}
        callback={() => handleOpen(setOcjURL ? constants.EARN_WHILE_YOU_SLEEP : modalType)}
        linkType={INTERNAL_LINK}
        testId="saveReservation_Button"
      />
    </div>
  ) : null;
};
