/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import {
  getSessionData,
  getPreProcessorUrl,
  isServerSession,
  getPreProcessorFromCookie,
  isServerPreprocessor,
  getDynamicPageModel,
  redirectTo,
  getPreProcessorRedirectURLs,
  evaluatePreData,
  targetPayloadGenerator,
  isSSTEnabled,
  getTAGPCookie,
} from './helper';
import { constants, URL_CONSTANTS } from '../constants';
import { getDataLayer } from './dataLayer';
import { RequestProps } from './dataLayer/index.types';
import { logger } from './logger';
import { interceptor } from './interceptor';
import { ServerResponse } from 'http';
import { axiosWrapper } from './axiosClient';
import { getTargetExpSegment } from '@marriott/shared/mi-helper-utils';
const { NEXT_PUBLIC_SESSION_KEYS } = process.env;
const { DATALAYERTYPE } = process.env;
const {
  ENV_DEV,
  SESSION_VALIDATION_FAILED_OR_EXPIRED,
  CART_VALIDATION_FAILED_OR_EXPIRED,
  DEFAULT_LANGUAGE,
  DEFAULT_LOCALE,
  MARRIOTT_BRAND_CODE,
  SERVER_TARGET_MBOX_CLIENT,
  SERVER_TARGET_MBOX_VERSION,
  TARGET_COOKIE_NAME,
  LOOKUP_RESERVATION_FOR_AUTH_FLOW_302_TO_MYTRIPS,
  IS_TAGP_COOKIE,
} = constants;
const { NEXT_PUBLIC_PROXY_HOST } = process.env;
const { NEXT_PUBLIC_MAXBRAND_DISABLE_FLAG } = process.env;
const { NEXT_PUBLIC_FALLBACK_PREPROCESSOR_MOCK_ENABLE = '' } = process.env;
const { NEXT_PUBLIC_BOOK_KEYS = '' } = process.env;
const {
  APOLLOGRAPHQL_PUBLIC_ACCEPT_LANG = '',
  APOLLOGRAPHQL_PUBLIC_CLIENT_VERSION = '',
  APOLLOGRAPHQL_PUBLIC_CLIENT_NAME = '',
  APOLLOGRAPHQL_PUBLIC_APPLICATION_NAME = '',
  APOLLOGRAPHQL_PUBLIC_REQUIRE_SAFELISTING = '',
  APOLLOGRAPHQL_FULL_NGINX_ENDPOINT = '',
  APOLLOGRAPHQL_PUBLIC_LOCAL_DEV = '',
  APOLLOGRAPHQL_PUBLIC_LOWER_ENV_PATH = '',
  APOLLOGRAPHQL_PUBLIC_HIGHER_ENV_PATH = '',
  DEPLOYED_ENV_TYPE = '',
  APOLLOGRAPHQL_PUBLIC_DEBUG_LOG = '',
  APOLLOGRAPHQL_LOCAL_ENDPOINT = '',
} = process.env;

declare global {
  // eslint-disable-next-line no-var
  var loggerInstance: CallableFunction;
}

const redirect = getPreProcessorRedirectURLs();

interface axiosResponseInterface extends AxiosResponse {
  notFound?: boolean;
  errorMessage?: string;
  experienceSegments?: { brandCode: string };
  locale?: string;
  languageTag?: string;
  navigationURL?: string;
}

/**
 *
 * @param url
 * @param header
 * @param body
 * @param isDev
 * @returns API response
 * This function is calling the pre-processir url and get the response, to be used in calling the page model.
 */
export const preProcessorCall = async (
  url: string,
  header: Record<string, string>,
  body: Record<string, string>,
  isDev: boolean
): Promise<axiosResponseInterface> => {
  const { log, pLog } = global.loggerInstance('preProcessorCall');
  log.debug(`API url: ${url}`);
  const apiStartTime = new Date().getTime();
  if (isDev) {
    // process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = '0'; //To avoid the SSL error on local env
  }
  let response;
  try {
    response = await axiosWrapper.post(url, body, {
      headers: header,
    });
    log.debug(`API call success with status code: ${response.status}`);
  } catch (e: any) {
    log.error(`API call failed: ${e}`);
    const status = e?.response?.data?.status;
    if (
      status === SESSION_VALIDATION_FAILED_OR_EXPIRED ||
      status === CART_VALIDATION_FAILED_OR_EXPIRED ||
      status === LOOKUP_RESERVATION_FOR_AUTH_FLOW_302_TO_MYTRIPS
    ) {
      log.error(status);
      return {
        ...(response?.data || {}),
        errorMessage: status,
      };
    } else {
      if (NEXT_PUBLIC_FALLBACK_PREPROCESSOR_MOCK_ENABLE === 'true') {
        log.error(`Preprocessor API mocked enabled because: ${e}`);
        const locale = header?.['accept-language']?.includes(DEFAULT_LANGUAGE)
          ? DEFAULT_LANGUAGE
          : header?.['accept-language'];
        const data = await preProcessorCallMocked('', locale, locale);
        return data as unknown as axiosResponseInterface;
      }
      throw new Error('PreProcessor call failed');
    }
  }
  if (response?.status !== 200) {
    log.error(`API url: ${url} | response status is: ${response?.status}`);
    return {
      ...(response?.data || {}),
      notFound: true,
      errorMessage: '',
    };
  }
  pLog.log('API call performance timing', apiStartTime, new Date().getTime());
  return response.data as axiosResponseInterface;
};

export const preProcessorCallMocked = async (
  brandCode = MARRIOTT_BRAND_CODE,
  locale = DEFAULT_LOCALE,
  languageTag = DEFAULT_LANGUAGE
) => {
  const { log, pLog } = global.loggerInstance('preProcessorCallMocked');
  const apiStartTime = new Date().getTime();
  let response;
  try {
    response = {
      status: 200,
      data: {
        experienceSegments: {
          hotelBrand: brandCode,
          brandCode: brandCode,
          ersProduct: 'false',
          programCode: null,
          ers: 'true',
        },
        locale: locale,
        userState: 'UnAuthenticated',
        notFound: false,
        errorMessage: '',
        languageTag: languageTag,
      },
    };
    log.debug(`API call success with status code: ${response.status}`);
  } catch (e) {
    log.error(`API call failed: ${e}`);
    throw new Error('PreProcessor call failed');
  }
  if (response?.status !== 200) {
    log.error(`response status is: ${response?.status}`);
    return {
      ...(response?.data || {}),
      notFound: true,
      errorMessage: '',
    };
  }
  pLog.log('API call performance timing', apiStartTime, new Date().getTime());
  return response.data;
};

/**
 *
 * @param gssp
 * @returns props
 * This function is used to excute the calls before the page gets rendered.
 * session, interceptor, pre-process, page model, datalayer
 */

export function prePageCall(gssp: (preData: any) => { props: any }) {
  return async (context: any) => {
    const {
      resolvedUrl,
      req,
      res,
    }: {
      resolvedUrl: string;
      req: {
        cookies?: {
          sessionID: string;
          UserIdToken: string;
          target_rendering_enabled: string;
          BVSAuthCookie: string;
          mippIDCookie: string;
        };
        headers: RequestProps;
        query: Record<string, unknown>;
        url: string;
        originalUrl: string;
      };
      res: ServerResponse;
    } = context;
    try {
      global.loggerInstance = logger({
        sessionID: req?.cookies?.sessionID,
        requestID: req.headers['x-request-id'],
      }) as CallableFunction;
      const contextUrl = resolvedUrl ?? req.originalUrl ?? req.url;
      const pageUrl = `${contextUrl?.split('?')?.[0]}`;
      const queryParams = `${contextUrl?.split('?')?.[1]}`;
      const requestId = req.headers['x-request-id'] ?? `${Date.now()}`;

      const preProcessorUrl = getPreProcessorUrl(pageUrl, queryParams);
      //CNWEB-3021 exacting the IS_CN_ENV, UXL_IMAGE_CACHE_DOMAIN  variable from process.env
      const { IS_CN_ENV, UXL_IMAGE_CACHE_DOMAIN } = process.env;
      const isDev = process.env['NODE_ENV'] === ENV_DEV;
      let cnEnvVars: { [key: string]: string | undefined } = {};
      // CNWEB-3021 Injecting the Image domain for UXL Image URLs through a env variable, in CN env only (behind IS_CN_ENV) check
      if (IS_CN_ENV) {
        const { log } = global.loggerInstance('preProcessorCallMocked');
        cnEnvVars = {
          UXL_IMAGE_CACHE_DOMAIN,
        };
        log.debug('cnEnvVars ', cnEnvVars);
      }

      const isFrame =
        contextUrl.includes('creditcardforreviewdetails') ||
        contextUrl.includes('creditcardforgi') ||
        contextUrl.includes('creditcardforrrdgi');

      const preHeader: Record<string, string> = {
        'Content-Type': 'application/json',
        'x-request-id': req.headers['x-request-id'] || '',
        Cookie: `sessionID=${req?.cookies?.sessionID};UserIdToken=${
          req?.cookies?.UserIdToken ? req?.cookies?.UserIdToken : ''
        }`,
        'x-host': req.headers['x-host'] || '',
        'accept-language': req.headers['accept-language'] || DEFAULT_LANGUAGE,
      };

      // Intercept the axios wrapper for the with pre headers
      axiosWrapper.intercept({
        [constants.ACCEPT_LANG]: req.headers['accept-language'] || '',
      });

      const { log, pLog } = global.loggerInstance('Pre Page');
      let sessionDataServer = null;
      const sessionBody = { keys: NEXT_PUBLIC_SESSION_KEYS }; //body to be send in session call
      const preBody = { locale: '' }; //body to be send in preprocessor call
      let cookieArr: string[] = [];

      if (isServerSession(pageUrl, req)) {
        //making server side session call
        const { sessionData, sessionHeader } = await getSessionData(preHeader, sessionBody);
        sessionDataServer = sessionData;

        cookieArr = sessionHeader['set-cookie'] || [];
        res.setHeader('Set-Cookie', cookieArr);
      }

      // Feature Flag check for brand 4 proeprties based on env variable and redirect to search unsuccessful page URL
      if (NEXT_PUBLIC_MAXBRAND_DISABLE_FLAG === 'true') {
        if ((sessionDataServer as any)?.cacheData?.data?.AriesReservation?.isMax) {
          const propertyId = (sessionDataServer as any)?.cacheData?.data?.AriesReservation?.propertyId;
          return {
            redirect: {
              permanent: false,
              destination: `${URL_CONSTANTS.UNAVAILABILITY_URL}${propertyId}`,
            },
          };
        }
      }

      //Interceptor Call
      const interceptorResponse = await interceptor(req, pageUrl);
      if (interceptorResponse?.status !== 200) {
        return redirectTo(res, redirect.redirect.destination, 307);
      }

      const { brandCode: brandCodeCookie, locale, languageTag } = getPreProcessorFromCookie(req);
      log.debug(`:::brandCodeCookie and locale and languageTag::: ${brandCodeCookie} ${locale} ${languageTag}`);

      const apiStartTime = new Date().getTime();
      log.debug('preData & datalayer started');

      const emptyDataObj = {
        data: [{}],
        mvpOffersData: {},
      };

      let predataWithDatalayer;
      if (!isServerPreprocessor(brandCodeCookie, locale)) {
        predataWithDatalayer = await Promise.all([
          preProcessorCallMocked(brandCodeCookie, locale, languageTag),
          // eslint-disable-next-line no-constant-condition
          DATALAYERTYPE === 'traditional' ? getDataLayer(req, sessionDataServer) : emptyDataObj,
        ]);
      } else {
        predataWithDatalayer = await Promise.all([
          preProcessorCall(preProcessorUrl || '', preHeader, preBody, isDev),
          // eslint-disable-next-line no-constant-condition
          DATALAYERTYPE === 'traditional' ? getDataLayer(req, sessionDataServer) : emptyDataObj,
        ]);
      }

      const [preData, dataLayer] = predataWithDatalayer;

      pLog.log('preData & datalayer success', apiStartTime, new Date().getTime());

      // TODO: Remove header after UAT testing
      log.debug(`req header res-is-sst-enabled: ${req?.headers?.['res-is-sst-enabled']}`);

      const serverExpFragArr: string[] = [];
      // this function calls the target and decide whether which recipe will be shown
      if (!isFrame) {
        if (isSSTEnabled(pageUrl, req.headers['res-is-sst-enabled'])) {
          const serverTargetResponse = await getTargetExpSegment(
            process.env['NEXT_PUBLIC_SERVER_TARGET_URL'],
            undefined,
            targetPayloadGenerator(dataLayer, sessionDataServer, pageUrl, req.headers, queryParams),
            {
              client: SERVER_TARGET_MBOX_CLIENT,
              version: SERVER_TARGET_MBOX_VERSION,
              sessionId: (sessionDataServer as any)?.sessionToken,
            }
          );
          log.debug(`target response: ${JSON.stringify(serverTargetResponse)}`);
          serverTargetResponse?.data?.execute?.mboxes?.forEach((mBox: { options: any[] }) => {
            if (mBox?.options?.length) {
              mBox?.options?.forEach(option => {
                if (option?.content) {
                  serverExpFragArr.push(option?.content);
                  res.setHeader('Set-Cookie', cookieArr?.concat([`${TARGET_COOKIE_NAME}= ${option?.content};`]));
                  cookieArr.push(`${TARGET_COOKIE_NAME}= ${option?.content};`);
                } else {
                  res.setHeader('Set-Cookie', cookieArr?.concat([`${TARGET_COOKIE_NAME}=;`]));
                  cookieArr.push(`${TARGET_COOKIE_NAME}=;`);
                }
              });
            } else {
              res.setHeader(
                'Set-Cookie',
                cookieArr?.concat([`${TARGET_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`])
              );
              cookieArr.push(`${TARGET_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`);
            }
          });
          log.debug(`recipe array: ${serverExpFragArr}`);
        } else {
          res.setHeader(
            'Set-Cookie',
            cookieArr?.concat([`${TARGET_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`])
          );
          cookieArr.push(`${TARGET_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`);
        }
      }

      //New method getDynamicPageModel for calling dynamic routing page model response
      const model = await getDynamicPageModel(
        pageUrl,
        preHeader,
        preData,
        sessionDataServer || { sessionToken: req.cookies?.sessionID },
        serverExpFragArr
      );

      const { subDirectoryContextPath = '' } = model || {};

      const { location, resCode } = evaluatePreData(preData, subDirectoryContextPath, pageUrl);

      if (location) {
        return redirectTo(res, location, resCode);
      }

      const apolloEnvVars = {
        APOLLOGRAPHQL_PUBLIC_ACCEPT_LANG,
        APOLLOGRAPHQL_PUBLIC_CLIENT_VERSION,
        APOLLOGRAPHQL_PUBLIC_CLIENT_NAME,
        APOLLOGRAPHQL_PUBLIC_APPLICATION_NAME,
        APOLLOGRAPHQL_PUBLIC_REQUIRE_SAFELISTING,
        APOLLOGRAPHQL_FULL_NGINX_ENDPOINT,
        APOLLOGRAPHQL_PUBLIC_LOCAL_DEV,
        APOLLOGRAPHQL_PUBLIC_LOWER_ENV_PATH,
        APOLLOGRAPHQL_PUBLIC_HIGHER_ENV_PATH,
        DEPLOYED_ENV_TYPE,
        APOLLOGRAPHQL_PUBLIC_DEBUG_LOG,
        APOLLOGRAPHQL_LOCAL_ENDPOINT,
      };

      const gsspData = gssp(preData); // Run `getServerSideProps` to get page-specific data
      const brandCode = preData?.experienceSegments?.brandCode?.toLowerCase() || MARRIOTT_BRAND_CODE;

      const graphqlLocale = preData?.languageTag || ''; //get the graphQL locale from preprocessor call
      const serverEnv: Record<string, string | undefined> = {};
      const parsedServerEnvs = process.env;
      NEXT_PUBLIC_BOOK_KEYS.split('|').forEach(_i => {
        if (parsedServerEnvs[_i]) {
          serverEnv[_i] = parsedServerEnvs[_i];
        }
      });

      const isTagpCookie = getTAGPCookie(req);
      if (isTagpCookie) {
        res.setHeader('Set-Cookie', cookieArr?.concat([`${IS_TAGP_COOKIE}=true;`]));
      } else {
        res.setHeader('Set-Cookie', cookieArr?.concat([`${IS_TAGP_COOKIE}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`]));
      }

      return {
        model: model ?? {},
        sessionData: sessionDataServer,
        brandCode,
        graphqlLocale,
        dataLayer,
        isIframeCard: isFrame,
        serverENV: JSON.stringify({ ...serverEnv, ...(isDev && { PROXY_HOST: NEXT_PUBLIC_PROXY_HOST }) }),
        ...gsspData.props,
        cnEnvVars,
        apolloEnvVars,
        requestId,
        isTagpCookie,
      };
    } catch (e) {
      const { log } = global.loggerInstance('Pre Page');
      log.error(`error occurred: ${e}`);
      // return redirect;
      return redirectTo(res, redirect.redirect.destination, 307);
    }
  };
}
