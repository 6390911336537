import axios, { AxiosRequestConfig } from 'axios';
import {
  ERROR_PATH,
  EXPIRED_OFFER,
  INELIGIBLE_OSERP_PARAM,
  INVALID_OFFER,
  INVALID_OFFERS_PARAM,
  OFFERS_APP_DOMAIN,
  OFFERS_HUB_PATH,
  REDIRECT_TO_URI,
  SIGN_IN_PAGE_URI,
  SIGN_IN_REDIRECT_FLOW,
  SUCCESS,
  SIGN_IN_URI,
  INVALID_OSERP_PARAM,
  INVALID_USER_OSERP_PARAM,
  INVALID_USER_HUB_PARAM,
} from './apiConstants';
import { getSubDirectoryLocale } from '../lib/helper';

declare global {
  // eslint-disable-next-line no-var
  var loggerInstance: CallableFunction;
}

export interface RequestHeaderType {
  currentLocale: string;
  requestId: string;
  sessionToken: string;
  resolvedUrl: string;
  hostname: string;
  url: string;
  referer: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchOffersData = async (requestHeader: RequestHeaderType, context: any, locale: string): Promise<any> => {
  const { req, res } = context;
  const { log } = global.loggerInstance('OffersService');
  let response = {};
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': requestHeader?.requestId ?? '',
      // 'Accept-Language': requestHeader?.currentLocale?.split(',')[0] ?? 'en-US',
      'Accept-Language': locale ?? 'en-US',
      sessionToken: requestHeader?.sessionToken ?? '',
    },
    data: {
      sessionToken: requestHeader?.sessionToken ?? '',
    },
  };
  console.log('request header url', requestHeader?.hostname, requestHeader.url);

  const hostname = `${requestHeader.hostname}${requestHeader.url}` ?? '';
  const pageURI = requestHeader?.url?.split('/offers/')[1] ?? '';
  const offerUrl = requestHeader?.resolvedUrl ?? '';
  const subDomain = getSubDirectoryLocale(locale);
  const referer = requestHeader?.referer ?? '';
  log.debug('[OffersService] pageURI & subdomain', pageURI, subDomain);
  log.debug('[OffersService] hostname', hostname);
  // const uri =
  // 'http://mi-offers-app-phoenix-offersdev1.dev-r.aws-use1.cloud.marriott.com/offers/details/heathrow-park-here-fly-there-off-15656/lhrhr-london-heathrow-marriott-hotel';
  const uri = `${OFFERS_APP_DOMAIN}${pageURI}`;

  if (!referer?.includes(SIGN_IN_URI)) {
    res?.setHeader('Set-Cookie', [`oserp_referer=${referer}; Path=/; Max-Age=63072000; Secure; SameSite=None`]);
  }

  console.log('[OffersService] request config data', JSON.stringify(config), uri);
  try {
    await axios.get(uri, config).then(({ data }) => {
      const responseCode = data?.responseStatus?.code ?? '';
      const responeMessage = data?.responseStatus?.message ?? '';
      console.log('[OffersService] data.uri::', data?.uri);
      if (responseCode === '200' && responeMessage === SUCCESS) {
        response = data ?? {};
      } else if (responseCode === '400' && responeMessage === REDIRECT_TO_URI) {
        if (data?.uri === SIGN_IN_REDIRECT_FLOW) {
          // Non logged in user
          res.writeHead(307, { Location: `${subDomain}${SIGN_IN_PAGE_URI}${offerUrl}` });
          res.end();
        } else {
          if (data?.uri?.includes('notEligible')) {
            if (req?.cookies?.oserp_referer?.includes('/findOffers')) {
              //Logged in ineligible user
              res?.writeHead(307, { Location: `${subDomain}${INELIGIBLE_OSERP_PARAM}` });
            } else {
              res?.writeHead(307, { Location: `${subDomain}${data?.uri}` ?? `${subDomain}${OFFERS_HUB_PATH}` });
            }
          } else if (data?.uri?.includes('invalidUser')) {
            if (req?.cookies?.oserp_referer?.includes('/findOffers')) {
              //Logged in invalid user
              res?.writeHead(307, { Location: `${subDomain}${INVALID_USER_OSERP_PARAM}` });
            } else {
              res?.writeHead(307, { Location: `${subDomain}${INVALID_USER_HUB_PARAM}` });
            }
          } else {
            res?.writeHead(307, { Location: `${subDomain}${data?.uri}` ?? `${subDomain}${OFFERS_HUB_PATH}` });
          }
          res.end();
        }
      } else if (responseCode === '400' && (responeMessage === EXPIRED_OFFER || responeMessage === INVALID_OFFER)) {
        if (referer?.includes('/findOffers')) {
          res.writeHead(307, { Location: `${subDomain}${INVALID_OSERP_PARAM}` });
        } else {
          res.writeHead(307, { Location: `${subDomain}${OFFERS_HUB_PATH}${INVALID_OFFERS_PARAM}` });
        }
        res.end();
      } else if (responseCode === '500' && responeMessage === 'SERVER_ERROR') {
        res.writeHead(307, { Location: `${subDomain}${ERROR_PATH}` });
        res.end();
      }

      log.debug('[OffersService] API call response::', JSON.stringify(data));
    });
  } catch (error) {
    log.debug('[OffersService] Error Data Message', error);
  }
  return { response };
};
