/* eslint-disable @typescript-eslint/no-explicit-any */

import { canUseDOM } from './helper';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const eventUtil = {
  on(event: any, callback: (data: any) => void) {
    canUseDOM && document.addEventListener(event, e => callback(e.detail));
  },
  dispatch(event: any, data: any) {
    canUseDOM && document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event: any, callback: () => void) {
    canUseDOM && document.removeEventListener(event, callback);
  },
};
