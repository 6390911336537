/* eslint-disable @typescript-eslint/no-explicit-any */
import { constants } from '../../constants';
import { MemberInformationMap, SessionMap } from './MemberInformation.schema';
import { parsedDataProps, queryDataProps } from './MemberInformation.types';

import { transformResponse } from '../../utils';
const {
  TA_FAM_AGENT,
  TA_PLAN_AGENT,
  PLANTASTIC_PRODUCTS,
  FAMTASTIC_PRODUCTS,
  TA_AGENT,
  CONCUR_ACCEPTED_LOCALES,
  CONCUR,
} = constants;

export const GI_FIELD_MAX_LENGTH = {
  firstName: 20,
  lastName: 25,
  primaryEmail: 47,
  secondaryEmail: 47,
  phoneNumber: 20,
  memberNumber: 9,
  addressLine1: 32, //32 myAccount Form
  addressLine2: 32, //32 myAccount Form
  city: 21, //20  myAccount Form
  postalCode: 10, //10  myAccount Form
  state: 4,
  state_CA: 2,
};
export const MI_FIELD_MAX_LENGTH = {
  ...GI_FIELD_MAX_LENGTH,
  phoneNumber: 57,
};
export const GI_FIELD_MIN_LENGTH = {
  [constants.USA_COUNTRY_CODE]: 5,
  [constants.CANADA_COUNTRY_CODE]: 5,
  DEFAULT: 0,
};
export const MI_FIELD_MIN_LENGTH = {
  ...GI_FIELD_MIN_LENGTH,
};
export const parseData = (data: queryDataProps, defaultCountry: string) => {
  const {
    firstName,
    lastName,
    localizedFirstName,
    localizedLastName,
    phone,
    emails,
    addresses,
    revisionToken,
    phones,
    phoneCountryCode,
    accountInfo,
    affiliate = {},
  } = transformResponse(data, MemberInformationMap);
  let primaryEmailIndex = emails.findIndex((item: { primary: boolean }) => {
    return item.primary;
  });
  primaryEmailIndex = primaryEmailIndex === -1 ? 0 : primaryEmailIndex;
  let addressIndex = addresses.findIndex((item: { primary: boolean }) => item.primary);
  addressIndex = addressIndex === -1 ? 0 : addressIndex;
  const primaryEmail = emails[primaryEmailIndex];
  const address = addresses[addressIndex]?.address;
  let restEmail = [...emails.slice(0, primaryEmailIndex), ...emails.slice(primaryEmailIndex + 1, emails.length)];
  const restAddress = [...addresses.slice(0, addressIndex), ...addresses.slice(addressIndex + 1, addresses.length)];
  let alternateEmail = { address: undefined };
  if (restEmail.length) {
    alternateEmail = restEmail[0];
    restEmail = restEmail.slice(1, restEmail.length);
  }
  const isConcurProfile = affiliate?.code === CONCUR && CONCUR_ACCEPTED_LOCALES.includes(defaultCountry);
  let concurAgencyId: string = '';
  if (Array.isArray(accountInfo) && accountInfo.length) {
    const agencyIdObj = accountInfo.find(item => item?.key === 'AGENCYID');
    concurAgencyId = agencyIdObj?.value ?? '';
  }

  return {
    firstName,
    lastName,
    localizedFirstName,
    localizedLastName,
    displayFirstName: firstName,
    displayLastName: lastName,
    phoneNumber: phone,
    primaryEmail: primaryEmail?.address,
    alternateEmail: alternateEmail?.address,
    addresses: restAddress,
    emails: restEmail,
    phones: phones.slice(1, phones.length),
    line1: address?.line1,
    line2: address?.line2,
    country: address?.country?.code,
    stateProvince: address?.stateProvince,
    postalCode: address?.postalCode,
    city: address?.city,
    revisionToken,
    phoneCountryCode,
    concurAgencyId,
    isConcurProfile,
  };
};

export const isMemberInfoStateUpdateRequired = (
  selectedCountry: string,
  postalCode: string,
  memberInformationState: Record<string, string | number | boolean>
) => {
  return (
    selectedCountry !== memberInformationState?.['country'] || postalCode !== memberInformationState?.['postalCode']
  );
};

export const getMemberCountryCode = (pageState: any) => {
  return pageState?.memberInformation?.phoneCountryCode ?? pageState?.memberInformation?.country;
};
export const getTAGPFlowFlag = (sessionObject: any, productRateId?: string, upcomingRes?: boolean) => {
  //   If travelAgentType in session is  "planAgent" && selected productRateCode is one of "["MPTP","MPTU","MPTV"]" OR

  // If travelAgentType in session is  "famAgent"  && selected productRateCode is one of ["HSSA","HSSF","HSSM","HSSN","FMHZ","ZZHO"]
  const { travelAgentType = '', productRateCode } = transformResponse(sessionObject, SessionMap);
  const productCode = productRateId ? productRateId : productRateCode;
  if (
    (travelAgentType === TA_PLAN_AGENT && PLANTASTIC_PRODUCTS.includes(productCode)) ||
    (travelAgentType === TA_FAM_AGENT && FAMTASTIC_PRODUCTS.includes(productCode)) ||
    (travelAgentType === TA_AGENT && upcomingRes)
  ) {
    return true;
  }
  return false;
};

/**
 * Validates if the member information fields (user and address) have been updated on form submission.
 *
 * @param {Object} memberInformation - The object containing member information and update flags.
 * @param {boolean} memberInformation.isUserFieldUpdated - Flag indicating whether the user fields have been updated.
 * @param {boolean} memberInformation.isAddressFieldUpdated - Flag indicating whether the address fields have been updated.
 * @param {Object} formData - The object containing the form data to compare against the member information.
 * @returns {Object} - An object containing updated flags for user and address fields.
 */
export function validateUpdatedMemberInfoOnSubmit(
  memberInformation: parsedDataProps & { isUserFieldUpdated: boolean; isAddressFieldUpdated: boolean },
  formData: parsedDataProps
): { isUserFieldUpdated: boolean; isAddressFieldUpdated: boolean } {
  const { firstName, lastName, phoneNumber, line1, line2, country, city, stateProvince, postalCode } =
    memberInformation;
  let { isUserFieldUpdated = false, isAddressFieldUpdated = false } = memberInformation;

  // If isUserFieldUpdated is already true, skip the computation
  if (!isUserFieldUpdated) {
    isUserFieldUpdated = !(
      firstName === formData.firstName &&
      lastName === formData.lastName &&
      phoneNumber === formData.phoneNumber
    );
  }

  // If isAddressFieldUpdated is already true, skip the computation
  if (!isAddressFieldUpdated) {
    isAddressFieldUpdated = !(
      line1 === formData.line1 &&
      line2 === formData.line2 &&
      country === formData.country &&
      city === formData.city &&
      stateProvince === formData.stateProvince &&
      postalCode === formData.postalCode
    );
  }

  return {
    isUserFieldUpdated,
    isAddressFieldUpdated,
  };
}

export const getZipCodeValidation = ({ selectedCountry = '', errorMessage = '' }) => {
  const postalCodeLength = GI_FIELD_MIN_LENGTH[selectedCountry] ?? GI_FIELD_MIN_LENGTH['DEFAULT'];
  return {
    minLength: {
      value: postalCodeLength,
      message: errorMessage, // JS only: <p>error message</p> TS only support string
    },
  };
};

// display name function
export const getDisplayName = (
  isAPACLocale: boolean,
  pageState?: { memberInformation?: { lastName?: string; firstName?: string } }
) => {
  const lastName = pageState?.memberInformation?.lastName;
  const firstName = pageState?.memberInformation?.firstName;

  if (isAPACLocale) {
    return `${lastName} ${firstName}`;
  }
  return `${firstName} ${lastName}`;
};
