import { FC } from 'react';

import { Image } from '../../atoms';
import { ImageCardProps } from './ImageCard.types';
import { StyledImageContainer } from './ImageCard.styles';

/* eslint-disable-next-line */

export const ImageCard: FC<ImageCardProps> = props => {
  const { offersId, offerCardImage, carouselImageSize, fetchpriority } = props;

  const renditions = offerCardImage?.renditions;
  const dynamic = offerCardImage?.dynamic;

  return (
    <StyledImageContainer data-component-name="m-book-ImageCard" data-testid="book-ImageCard" theme={carouselImageSize}>
      <div className="image-wrapper">
        <Image
          url={offerCardImage?.damPath}
          renditions={renditions}
          dynamic={dynamic}
          title={offersId}
          appliedClass={'room-image'}
          size={carouselImageSize?.sizes}
          fetchpriority={fetchpriority}
        />
      </div>
    </StyledImageContainer>
  );
};
