import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledSection = styled.div`
  &.bottomContainer {
    grid-template-rows: ${toRem(1)} ${toRem(26)} repeat(3, ${toRem(60)});
  }
  &.grid-container {
    display: grid;
    row-gap: ${toRem(32)};
    column-gap: ${toRem(16)};
    grid-template-columns: repeat(10, 1fr);
  }
  &.US-container,
  &.AU-container,
  &.CA-container {
    .country-field {
      grid-column: 1/6;
      grid-row: 3 / 5;
    }
    .line1-field {
      grid-column: 1/6;
      grid-row: 4 / 5;
    }
    .line2-field {
      grid-column: 6/11;
      grid-row: 4 / 5;
    }
    .city-field {
      grid-column: 1/6;
      grid-row: 5 / 5;
    }
    .state-field {
      grid-column: 6/9;
      grid-row: 5 / 5;
    }
    .code-field {
      grid-column: 9/11;
      grid-row: 5 / 5;
    }
  }
  &.EL-container {
    .country-field {
      grid-column: 1/6;
      grid-row: 3 / 5;
    }
    .line1-field {
      grid-column: 1/6;
      grid-row: 4 / 5;
    }
    .line2-field {
      grid-column: 6/11;
      grid-row: 4 / 5;
    }
    .city-field {
      grid-column: 1/4;
      grid-row: 5 / 5;
    }
    .state-field {
      grid-column: 4/7;
      grid-row: 5 / 5;
    }
    .code-field {
      grid-column: 7/11;
      grid-row: 5 / 5;
    }
  }
  &.DE-container,
  &.FR-container {
    .country-field {
      grid-column: 1/6;
      grid-row: 3 / 5;
    }
    .line1-field {
      grid-column: 1/6;
      grid-row: 4 / 5;
    }
    .line2-field {
      grid-column: 6/11;
      grid-row: 4 / 5;
    }
    .city-field {
      grid-column: 6/11;
      grid-row: 5 / 5;
    }
    .state-field {
      grid-column: 6/9;
      grid-row: 5 / 5;
    }
    .code-field {
      grid-column: 1/6;
      grid-row: 5 / 5;
    }
  }
  &.MX-container,
  &.ES-container {
    .country-field {
      grid-column: 1/6;
      grid-row: 3 / 5;
    }
    .line1-field {
      grid-column: 1/6;
      grid-row: 5 / 5;
    }
    .line2-field {
      grid-column: 6/11;
      grid-row: 5 / 5;
    }
    .city-field {
      grid-column: 6/11;
      grid-row: 4 / 5;
    }
    .code-field {
      grid-column: 1/6;
      grid-row: 4 / 5;
    }
  }
  &.CN-container,
  &.JP-container {
    .country-field {
      grid-column: 1/6;
      grid-row: 3/6;
    }
    .line1-field {
      grid-column: 1/6;
      grid-row: 5/6;
    }
    .line2-field {
      grid-column: 6/11;
      grid-row: 5/6;
    }
    .city-field {
      grid-column: 6/11;
      grid-row: 4/6;
    }
    .state-field {
      grid-column: 1/6;
      grid-row: 4/6;
    }
    .code-field {
      grid-column: 1/3;
      grid-row: 6/6;
      .information {
        width: max-content;
        margin-left: 0;
      }
    }
  }
  &.IN-container {
    .country-field {
      grid-column: 1/6;
      grid-row: 3/5;
    }
    .line1-field {
      grid-column: 1/6;
      grid-row: 4/5;
    }
    .line2-field {
      grid-column: 6/11;
      grid-row: 4/5;
    }
    .city-field {
      grid-column: 1/6;
      grid-row: 5/5;
    }
    .state-field {
      grid-column: 6/9;
      grid-row: 5/5;
    }
    .code-field {
      grid-column: 9/11;
      grid-row: 5/5;
    }
  }
  &.hybrid-section,
  &.upRes-section {
    &.EL-container {
      .country-field {
        grid-column: 1/6;
        grid-row: 1 / 5;
      }
      .line1-field {
        grid-column: 1/6;
        grid-row: 3 / 5;
      }
      .line2-field {
        grid-column: 6/11;
        grid-row: 3 / 5;
      }
      .city-field {
        grid-column: 1/6;
        grid-row: 4 / 5;
      }
      .state-field {
        grid-column: 6/11;
        grid-row: 4 / 5;
      }
      .code-field {
        grid-column: 1/6;
        grid-row: 5 / 5;
      }
    }
    &.US-container,
    &.AU-container,
    &.CA-container,
    &.IN-container {
      .country-field {
        grid-column: 1/6;
        grid-row: 1 / 5;
      }
      .line1-field {
        grid-column: 1/6;
        grid-row: 3 / 5;
      }
      .line2-field {
        grid-column: 6/11;
        grid-row: 3 / 5;
      }
      .city-field {
        grid-column: 1/6;
        grid-row: 4 / 5;
      }
      .state-field {
        grid-column: 6/11;
        grid-row: 4 / 5;
      }
      .code-field {
        grid-column: 1/4;
        grid-row: 5 / 5;
      }
    }
    &.CN-container,
    &.JP-container {
      .country-field {
        grid-column: 1/6;
        grid-row: 1 / 5;
      }
      .state-field {
        grid-column: 1/6;
        grid-row: 3 / 5;
      }
      .city-field {
        grid-column: 6/11;
        grid-row: 3 / 5;
      }
      .line1-field {
        grid-column: 1/6;
        grid-row: 4 / 5;
      }
      .line2-field {
        grid-column: 6/11;
        grid-row: 4 / 5;
      }
      .code-field {
        grid-column: 1/4;
        grid-row: 5 / 5;
      }
    }
    &.DE-container,
    &.FR-container {
      &.bottomContainer {
        grid-template-rows: ${toRem(1)} ${toRem(26)} repeat(2, ${toRem(60)});
      }
      .country-field {
        grid-column: 1/6;
        grid-row: 1 / 5;
      }
      .line1-field {
        grid-column: 1/6;
        grid-row: 3 / 5;
      }
      .line2-field {
        grid-column: 6/11;
        grid-row: 3 / 5;
      }
      .city-field {
        grid-column: 6/11;
        grid-row: 4 / 5;
      }
      .state-field {
        grid-column: 6/11;
        grid-row: 4 / 5;
      }
      .code-field {
        grid-column: 1/6;
        grid-row: 4 / 5;
      }
    }
    &.MX-container,
    &.ES-container {
      &.bottomContainer {
        grid-template-rows: ${toRem(1)} ${toRem(26)} repeat(2, ${toRem(60)});
      }
      .country-field {
        grid-column: 1/6;
        grid-row: 1 / 5;
      }
      .line1-field {
        grid-column: 1/6;
        grid-row: 4 / 5;
      }
      .line2-field {
        grid-column: 6/11;
        grid-row: 4 / 5;
      }
      .city-field {
        grid-column: 6/11;
        grid-row: 3 / 5;
      }
      .code-field {
        grid-column: 1/6;
        grid-row: 3 / 5;
      }
    }
  }

  .divider {
    grid-column: 1/11;
    border-bottom: 1px solid ${theme.colors['base-10']};
  }

  @media ${theme.mediaQuery.allMobiles} {
    &.bottomContainer {
      grid-template-rows: ${toRem(1)} ${toRem(26)} repeat(6, ${toRem(60)});
    }
    &.grid-container {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 0;
    }
    &.US-container,
    &.AU-container,
    &.CA-container,
    &.EL-container {
      .country-field {
        grid-column: 1/10;
        grid-row: 3 / 8;
      }
      .line1-field {
        grid-column: 1/10;
        grid-row: 4 / 8;
      }
      .line2-field {
        grid-column: 1/10;
        grid-row: 5 / 8;
      }
      .city-field {
        grid-column: 1/10;
        grid-row: 6 / 8;
      }
      .state-field {
        grid-column: 1/10;
        grid-row: 7 / 8;
      }
      .code-field {
        grid-column: 1/10;
        grid-row: 8 / 8;
      }
    }
    &.CN-container,
    &.JP-container {
      .country-field {
        grid-column: 1/10;
        grid-row: 3/8;
      }
      .line1-field {
        grid-column: 1/10;
        grid-row: 6/8;
      }
      .line2-field {
        grid-column: 1/10;
        grid-row: 7/8;
      }
      .city-field {
        grid-column: 1/10;
        grid-row: 5/8;
      }
      .state-field {
        grid-column: 1/10;
        grid-row: 4/8;
      }
      .code-field {
        grid-column: 1/10;
        grid-row: 8/8;
      }
    }
    &.IN-container {
      .country-field {
        grid-column: 1/10;
        grid-row: 3/8;
      }
      .line1-field {
        grid-column: 1/10;
        grid-row: 4/8;
      }
      .line2-field {
        grid-column: 1/10;
        grid-row: 5/8;
      }
      .city-field {
        grid-column: 1/10;
        grid-row: 6/8;
      }
      .state-field {
        grid-column: 1/10;
        grid-row: 7/8;
      }
      .code-field {
        grid-column: 1/10;
        grid-row: 8/8;
      }
    }
    &.DE-container,
    &.FR-container {
      &.bottomContainer {
        grid-template-rows: ${toRem(1)} ${toRem(26)} repeat(5, ${toRem(60)});
      }
      .country-field {
        grid-column: 1/10;
        grid-row: 3 / 8;
      }
      .line1-field {
        grid-column: 1/10;
        grid-row: 4 / 8;
      }
      .line2-field {
        grid-column: 1/10;
        grid-row: 5 / 8;
      }
      .city-field {
        grid-column: 1/10;
        grid-row: 7 / 8;
      }
      .state-field {
        grid-column: 1/10;
        grid-row: 7 / 8;
      }
      .code-field {
        grid-column: 1/10;
        grid-row: 6 / 8;
      }
    }
    &.MX-container,
    &.ES-container {
      &.bottomContainer {
        grid-template-rows: ${toRem(1)} ${toRem(26)} repeat(5, ${toRem(60)});
      }
      .country-field {
        grid-column: 1/10;
        grid-row: 3 / 8;
      }
      .line1-field {
        grid-column: 1/10;
        grid-row: 6 / 8;
      }
      .line2-field {
        grid-column: 1/10;
        grid-row: 7 / 8;
      }
      .city-field {
        grid-column: 1/10;
        grid-row: 5 / 8;
      }
      .code-field {
        grid-column: 1/10;
        grid-row: 4 / 8;
      }
    }
    &.hybrid-section,
    &.upRes-section {
      &.bottomContainer {
        grid-template-rows: ${toRem(1)} ${toRem(26)} repeat(5, ${toRem(60)});
      }

      &.US-container,
      &.AU-container,
      &.CA-container,
      &.IN-container,
      &.EL-container {
        .country-field {
          grid-column: 1/10;
          grid-row: 1/ 8;
        }
        .line1-field {
          grid-column: 1/10;
          grid-row: 3/ 8;
        }
        .line2-field {
          grid-column: 1/10;
          grid-row: 4 / 8;
        }
        .city-field {
          grid-column: 1/10;
          grid-row: 5/ 8;
        }
        .state-field {
          grid-column: 1/10;
          grid-row: 6/ 8;
        }
        .code-field {
          grid-column: 1/10;
          grid-row: 7/ 8;
          width: 60%;
        }
      }
      &.CN-container,
      &.JP-container {
        .country-field {
          grid-column: 1/10;
          grid-row: 1/ 8;
        }
        .state-field {
          grid-column: 1/10;
          grid-row: 3/ 8;
        }
        .city-field {
          grid-column: 1/10;
          grid-row: 4 / 8;
        }
        .line1-field {
          grid-column: 1/10;
          grid-row: 5/ 8;
        }
        .line2-field {
          grid-column: 1/10;
          grid-row: 6/ 8;
        }
        .code-field {
          grid-column: 1/10;
          grid-row: 7/ 8;
          width: 60%;
        }
      }
      &.DE-container,
      &.FR-container {
        .country-field {
          grid-column: 1/10;
          grid-row: 1/ 7;
        }
        .line1-field {
          grid-column: 1/10;
          grid-row: 3/ 7;
        }
        .line2-field {
          grid-column: 1/10;
          grid-row: 4 / 7;
        }
        .city-field {
          grid-column: 1/10;
          grid-row: 7/ 7;
        }
        .code-field {
          grid-column: 1/10;
          grid-row: 5/ 7;
        }
      }
      &.MX-container,
      &.ES-container {
        .country-field {
          grid-column: 1/10;
          grid-row: 1/ 7;
        }
        .line1-field {
          grid-column: 1/10;
          grid-row: 5/ 7;
        }
        .line2-field {
          grid-column: 1/10;
          grid-row: 7 / 7;
        }
        .city-field {
          grid-column: 1/10;
          grid-row: 4/ 7;
        }
        .code-field {
          grid-column: 1/10;
          grid-row: 3/ 7;
        }
      }
    }
    &.upRes-section {
      &.US-container,
      &.AU-container,
      &.CA-container,
      &.IN-container,
      &.CN-container,
      &.JP-container,
      &.EL-container {
        .code-field {
          width: 50%;
        }
      }
    }
  }

  @media ${theme.mediaQuery.minDesktop} {
    &.upRes-section {
      &.EL-container {
        grid-template-rows: ${toRem(1)} ${toRem(26)} repeat(2, ${toRem(60)});
        .country-field,
        .line1-field,
        .city-field {
          grid-column: 1/5;
        }
        .line2-field {
          grid-column: 5/9;
        }
        .state-field {
          grid-column: 5/7;
          select {
            padding-right: ${toRem(32)};
          }
        }
        .code-field {
          grid-column: 1/5;
          grid-row: 4 / 5;
        }
      }
      &.US-container,
      &.AU-container,
      &.CA-container,
      &.IN-container {
        grid-template-rows: ${toRem(1)} ${toRem(26)} repeat(2, ${toRem(60)});
        .country-field,
        .line1-field,
        .city-field {
          grid-column: 1/5;
        }
        .line2-field {
          grid-column: 5/9;
        }
        .state-field {
          grid-column: 5/7;
          select {
            padding-right: ${toRem(32)};
          }
        }
        .code-field {
          grid-column: 7/9;
          grid-row: 4 / 5;
        }
      }
      &.CN-container,
      &.JP-container,
      &.DE-container,
      &.FR-container {
        .country-field,
        .line1-field,
        .code-field {
          grid-column: 1/5;
        }
        .city-field,
        .line2-field {
          grid-column: 5/9;
        }
      }
      &.MX-container,
      &.ES-container {
        .code-field {
          grid-column: 1/5;
          grid-row: 3/ 5;
        }
        .city-field {
          grid-column: 5/9;
        }
      }
      &.CN-container,
      &.JP-container {
        .state-field {
          grid-column: 1/5;
        }
        .code-field {
          grid-column: 1/3;
          grid-row: 5 / 5;
        }
      }
    }
  }
`;
