/* eslint-disable @typescript-eslint/no-explicit-any */
import { sanitize } from 'isomorphic-dompurify';
import { constants, URL_CONSTANTS } from '../constants';
import { getProcessEnvs, getWindowSession, addSubDirectoryPrefix } from './helper';
import { axiosWrapper } from './axiosClient';

declare global {
  export interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $: any; //Funtion type is giving eslint error
    modalCssStatus: Record<string, boolean>;
  }
}

const { ENV_DEV, RES_SIGNIN_MODAL, OCJ_MODAL, MI_APP, MI_SS, UNAUTHENTICATED } = constants;
const { MI_CORE_JS, MI_COMMON_JS, RES_SIGNIN_OVERLAY } = URL_CONSTANTS;

function checkModalCssStatus(elementId: string) {
  console.log('0004', elementId);
  try {
    if (!Object.values(window.modalCssStatus).includes(false)) {
      const modalElement = document.getElementById(elementId);
      modalElement?.classList.remove('hide-component');
    }
  } catch (e) {
    console.log('Exception in evalModalCssStatus', e);
  }
}
function cloneAttributes(target: HTMLElement, source: HTMLElement) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    [...source.attributes].forEach(attr => {
      target.setAttribute(attr.nodeName === 'id' ? 'data-id' : attr.nodeName, attr.nodeValue || '');
    });
  } catch (e) {
    console.log('Error in cloneAttributes', e);
  }
}

function appendCSSWithCallback(tag: HTMLLinkElement | HTMLAnchorElement, callbackFn: any) {
  try {
    console.log('tag 01', tag.href);
    window.modalCssStatus = { ...window.modalCssStatus, [tag.href]: false };
    const linkElement = document.createElement('link');
    linkElement.rel = 'stylesheet';
    console.log('linkElement 01', linkElement);
    cloneAttributes(linkElement, tag);
    console.log('linkElement 02', linkElement);
    linkElement.onload = () => {
      window.modalCssStatus = { ...window.modalCssStatus, [tag.href]: true };
      callbackFn?.();
    };
    document.head.appendChild(linkElement);
  } catch (e) {
    console.log('Exception in appendCSSWithCallback', e);
  }
}

const appendAndLoadScripts = (tag: HTMLScriptElement, elementId = '') => {
  if (elementId === OCJ_MODAL) {
    window.$('body').append(tag);
  } else {
    const newScript = document.createElement('script');
    newScript.type = 'text/javascript';
    cloneAttributes(newScript, tag);
    newScript.src = tag.src;
    document.body.appendChild(newScript);
  }
};

const processEnv = getProcessEnvs();

const parseHTMLResponse = (
  overlayResponse: string,
  overlayParsedOnce: { value: boolean },
  removeLoadScript?: boolean,
  showModalOnLoad?: boolean,
  buttonClick?: boolean,
  elementId = ''
): string => {
  if (process.env['NODE_ENV'] === ENV_DEV) {
    overlayResponse = overlayResponse.replace(/"\/aries/g, `"${processEnv['PROXY_HOST']}/aries`);
    overlayResponse = overlayResponse.replace(/"\/common/g, `"${processEnv['PROXY_HOST']}/common`);
  }
  const frag = window.$(overlayResponse);
  console.log('007', frag);
  if (!overlayParsedOnce.value) {
    const fragTags = frag.filter('script.component-tag, script#miDataLayer');
    console.log('fragTags', frag.filter('link[rel="stylesheet"]').length);
    // If no external css is there in HTML then removing hide-component class
    if (frag.filter('link[rel="stylesheet"]').length === 0) {
      const modalElement = document.getElementById(elementId);
      console.log('modalElement', modalElement);
      //Waiting for injectModal funtion to inject HTML then removing hide-component class.
      setTimeout(() => {
        modalElement?.classList.remove('hide-component');
      }, 0);
    }

    frag.filter('link[rel="stylesheet"]').each(function (_: undefined, tag: HTMLAnchorElement) {
      if (removeLoadScript) {
        tag.setAttribute('data-remove', 'true');
      } else if (tag.getAttribute('href')?.includes('foundation.css')) {
        tag.setAttribute('data-remove', 'true');
      }

      if (tag.getAttribute('href')?.includes('.css')) {
        console.log('elementId', elementId);
        appendCSSWithCallback(tag, () => {
          checkModalCssStatus(elementId);
        });
      }
    });

    // Fetch component static files and process rendered components
    fragTags.filter('script.component-tag').each(function (this: object, _: undefined, tag: HTMLScriptElement) {
      const src = window.$(this).attr('src');
      //eslint-disable-next-line no-eval
      if (removeLoadScript) {
        tag.setAttribute('data-remove', 'true');
      }
      // eslint-disable-next-line no-new-func
      src ? appendAndLoadScripts(tag, elementId) : Function(window.$(this).text())();
    });

    // if these files are not added in the body already then add them
    frag.filter('script').each(function (this: object, _: undefined, tag: HTMLScriptElement) {
      const src = window.$(this).attr('src');
      if (removeLoadScript) {
        tag.setAttribute('data-remove', 'true');
      }
      if (src && (src.indexOf(MI_APP) !== -1 || src.indexOf(MI_SS) !== -1)) {
        appendAndLoadScripts(tag, elementId);
      }
    });

    if ((showModalOnLoad && buttonClick) || !showModalOnLoad) {
      overlayParsedOnce.value = true;
    }

    frag.filter("script[type='text/mi-context']").each(function (_: undefined, tag: HTMLScriptElement) {
      if (removeLoadScript) {
        tag.setAttribute('data-remove', 'true');
      }
      window.$('body').prepend(tag);
    });
  }
  console.log('frag', frag.filter('#marriott-main-content').html());

  const modalContent = frag.filter('#marriott-main-content').html() || frag.filter('.modal-content').html();
  return modalContent ?? frag.filter('.root').html();
};

/**
 * Injects Scripts after page Load.
 * Delay has been added since vendor js is taking more time as compared to other files
 */
const injectDependency = (list: Array<string>): void => {
  list.forEach(v => {
    const script = document.createElement('script');
    script.src = v;
    document.body.appendChild(script);
  });
};

export const injectModalContent = (
  content: string,
  elementId: string,
  statusCode: number,
  callableFunction?: CallableFunction
) => {
  const ele = document.getElementById(elementId);
  if (!ele) return;
  if (ele.className && statusCode === 200) ele.classList.remove('loading');
  ele.replaceChildren();
  ele.append(sanitize(content, { RETURN_DOM_FRAGMENT: true }));
  const elements = [RES_SIGNIN_MODAL, OCJ_MODAL];
  if (elements.includes(elementId)) ele.setAttribute('aria-live', 'polite');
  callableFunction?.();
  elements.includes(elementId) &&
    setTimeout(() => {
      ele.removeAttribute('aria-live');
    }, 2000);
  ele.classList.add('hide-component');
};

export const loadModalData = (
  overlayParsedOnce: { value: boolean },
  url: string,
  elementId: string,
  disableJSCall?: boolean | undefined,
  removeLoadScript?: boolean, //If you want to remove all the script on modal calls
  callableFunction?: CallableFunction,
  showModalOnLoad?: boolean,
  buttonClick?: boolean
) => {
  const signinUrl = url;
  const isAkamaiCachingEnabled = processEnv['NEXT_PUBLIC_SIGNIN_AKAMAI_ENABLED'] === 'true';
  const isResSignInOverlay = url === addSubDirectoryPrefix(RES_SIGNIN_OVERLAY);

  // Value for NEXT_PUBLIC_SIGIN_AKAMAI_HEADER should be header:valueTrue:valueFalse
  const akamaiHeaderDetails = String(processEnv['NEXT_PUBLIC_SIGIN_AKAMAI_HEADER'] || '').split(':');
  const sessionData = getWindowSession();
  const userState = sessionData?.cacheData?.data?.AriesCommon?.memState;
  const isAkamaiCaching = userState === UNAUTHENTICATED && isAkamaiCachingEnabled && isResSignInOverlay;
  const akamaiHeaderName = akamaiHeaderDetails[0] || 'Cache-Override';
  const akamaiHeaderEnableValue = akamaiHeaderDetails[1] || 'cache';
  const akamaiHeaderDisableValue = akamaiHeaderDetails[2] || 'disabled';

  const akamaiHeaderObject = {
    [akamaiHeaderName]: akamaiHeaderDisableValue,
  };

  if (isAkamaiCaching) {
    akamaiHeaderObject[akamaiHeaderName] = akamaiHeaderEnableValue;
  }

  let waitForScriptsToLoad;
  if (!overlayParsedOnce.value) {
    // inject old aries scripts to footer
    let ariesJsArr = [MI_CORE_JS, MI_COMMON_JS];
    if (process.env['NODE_ENV'] === ENV_DEV) {
      ariesJsArr = ariesJsArr.map(src => {
        src = src.replace('/aries', `${processEnv['PROXY_HOST']}/aries`);
        return src;
      });
    }
    if (disableJSCall !== false) {
      injectDependency(ariesJsArr);
    }
    axiosWrapper
      .get(signinUrl, {
        headers: {
          Accept: 'text/html',
          ...(isResSignInOverlay && akamaiHeaderObject),
        },
      })
      .then(res => {
        if (res?.status === 200) {
          return res?.data;
        }
        throw new Error('Something went wrong');
      })
      .then(res => {
        const APIHtmlContent = res;
        waitForScriptsToLoad = setTimeout(async () => {
          // Timeout added to delay the injection of aries scripts in DOM
          const modalContent = parseHTMLResponse(
            APIHtmlContent,
            overlayParsedOnce,
            removeLoadScript,
            showModalOnLoad,
            buttonClick,
            elementId
          );
          injectModalContent(modalContent, elementId, 200, callableFunction); //show the actual modal content
        }, 1000);
      })
      .catch(e => {
        injectModalContent(e.message, elementId, 400);
      });
    clearTimeout(waitForScriptsToLoad);
  }
};
