import cssClass from 'classnames';
import { constants } from '../../constants';
import { RoomFeatureProps } from './RoomFeature.types';
import { StyledDiv } from './RoomFeature.styles';

const { ICON_MAPPER } = constants;

export const RoomFeature = (props: RoomFeatureProps) => {
  const { featureName, featureCode, featureValues, isWiFiText = false } = props;

  if (featureValues.length === 0) {
    return null;
  }

  return (
    <StyledDiv data-component-name="m-book-RoomFeature" data-testid="book-RoomFeature">
      <header className="header">
        <div>
          <span className={`${ICON_MAPPER[featureCode as keyof typeof ICON_MAPPER]}`}></span>
        </div>
        <div className="header-text">{featureName}</div>
      </header>
      <ul>
        {featureValues.map((item: string | { translatedText: string }, index: number) => {
          const listItemClass = cssClass('list-item', {
            'wifi-text': isWiFiText && index === featureValues.length - 1,
            'mb-0': index === featureValues.length - 1,
          });
          const displayText = typeof item === 'object' ? item.translatedText : item;
          return (
            <li className={listItemClass} key={index}>
              {displayText}
            </li>
          );
        })}
      </ul>
    </StyledDiv>
  );
};
